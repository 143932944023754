import firebase from "firebase/app";
import { getPhonePrefix } from "config";

export const getAuthMethods = async (brand) => {
  const active_methods = brand.brand_authentication_methods.filter(
    (method) => method.is_active === true
  );

  let options = [];

  active_methods.forEach((method) => {
    switch (method.name) {
      case "SMS":
        options.push({
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          fullLabel: 'Fazer login com telefone',
          recaptchaParameters: {
            type: "image",
            size: "invisible",
            badge: "bottomleft",
          },
          defaultCountry: "BR",
          defaultNationalNumber: `${getPhonePrefix()}`,
        });
        break;

      case "Website":
        options.push({
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          fullLabel: 'Fazer login com e-mail'
        });
        break;

      case "Facebook":
        options.push({
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          fullLabel: 'Fazer login com Facebook'
        });
        break;

      case "Google":
        options.push({
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          fullLabel: 'Fazer login com Google'
        });
        break;

      case "Apple":
        options.push({
          provider: "apple.com",
        });
        break;
    }
  });

  return options;
};


export const getGoogleAuthenticationMethod = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    console.error(error);
  }
}

export const getEmailAuthenticationMethod = async (email, password) => {
  try {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    return response
  } catch (error) {
    console.log(error)
    return error
  }
};

export const createAccountWithEmailAndPassword = async (email, password, name) => {
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await userCredential.user.updateProfile({
      displayName: name
    })
    return userCredential.user;
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getPhoneAuthenticationMethod = async (phone, appVerifier) => {
  try {
    const response = await firebase.auth().signInWithPhoneNumber(phone, appVerifier)
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getPhoneAuthenticationCredential = async (appVerifier, code) => {
  try {
    const response = firebase.auth.PhoneAuthProvider.credential(appVerifier, code)
    const success = await firebase.auth().signInWithCredential(response)
    return success
  } catch (error) {
    console.log(error)
    return error
  }
}