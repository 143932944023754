import React, { useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink, Container } from "reactstrap";
import { domainColorStyle } from "config";
import { getCustomerByPhone } from "helpers/apiHelper";
import { sendCategoryEvent } from "helpers/apis/impulseEventsApiHelper";
import { IconButton } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { changeSelectedSubcategory, changeSelectedFilter } from "actions/categoriesActions";
import { useDispatch, useSelector } from "react-redux";
import { getMixesFromSubcategoryAndFilter } from "helpers/storeHelper";
import { filterProductsChanged, subcategoryProductsChanged } from "actions/productsActions";
import { getMarketItemsByCategory } from "helpers/apis/marketInfo";

function SubcategoryTabs({
  category
}) {
  const [user, setUser] = useState({});
  const menu = useRef(null)
  const [currentSubcategory, setCurrentSubcategory] = useState(0)
  const [currentFilter, setCurrentFilter] = useState(undefined)
  const dispatch = useDispatch()
  const subcategories = useSelector(({categories}) => categories.subcategories)
  const selectedSubcategory = useSelector(({categories}) => categories.selectedSubcategory)
  const filters = useSelector(({categories}) => categories.selectedSubcategory?.subcategories)

  const categoryProducts = useSelector(({ products }) => products.categoryProducts)
  const selectedCategory = useSelector(({categories}) => categories.selectedCategory)
  const marketId = useSelector(({markets}) => markets.id)
  const deliveryMethodId = useSelector(({ deliveryMethod }) => deliveryMethod?.deliveryMethod.id)
  const neighborhood = useSelector(({zone}) => zone?.neighborhood_id)
  const colorDomain = !category.color ? domainColorStyle("background") : category.color

  const getSubcatOrFilterItems = async (mixes, selCat, selSubcat, selFilter) => {
    
    const categoryMix = await getMarketItemsByCategory(
        marketId, 
        selSubcat.id, 
        deliveryMethodId, 
        neighborhood
      )
      
    const allItems = [];
    if (categoryMix && categoryMix.mixes && Array.isArray(categoryMix.mixes)) {
      // Iterando sobre cada mix
      categoryMix.mixes.forEach(mix => {
        // Verificando se o mix tem a propriedade 'items' e se 'items' é um array
        if (mix.items && Array.isArray(mix.items)) {
          // Adicionando os itens do mix ao array allItems
            allItems.push(...mix.items)
        }
      })
    } 

    const items = getMixesFromSubcategoryAndFilter(
      mixes,
      selCat,
      selSubcat,
      selFilter
    );

    if (selFilter) {
      dispatch(filterProductsChanged(items))
    } else {
      dispatch(subcategoryProductsChanged(allItems))
    }
  }

  useEffect(() => {
    const phone = localStorage.getItem("USERPHONE");
    if (phone) {
      getCustomerByPhone(phone, (error, data) => {
        if (error) {
          console.log(error);
        } else {
          const { name, birthDate } = data.customer;
          const birthday = birthDate.split("/");
          const date = new Date(birthday[2], birthday[1], birthday[0]);
          const ageDifMs = Date.now() - date.getTime();
          const ageDate = new Date(ageDifMs);
          const age = Math.abs(ageDate.getUTCFullYear() - 1970);
          setUser({ age, name });
        }
      });
    }
  }, []);

  function scrollNext(ref) {
    ref.current.scrollBy({ left: 100, behavior: "smooth" });
  }
  function scrollBefore(ref) {
    ref.current.scrollBy({ left: -100, behavior: "smooth" });
  }

  function changeSubcategory(subcategory, key) {
    setCurrentSubcategory(key)
    if (subcategory.id) {
      getSubcatOrFilterItems(categoryProducts, selectedCategory, subcategory)
      dispatch(changeSelectedSubcategory(subcategory));
    } else {
      dispatch(changeSelectedSubcategory(undefined));
    }
  }

  function changeFilter(filter, key) {
    setCurrentFilter(key);
    if (filter.id) {
      getSubcatOrFilterItems(categoryProducts, selectedCategory, selectedSubcategory, filter)
      dispatch(changeSelectedFilter(filter));
    } else {
      getSubcatOrFilterItems(categoryProducts, selectedCategory, selectedSubcategory)
      dispatch(changeSelectedFilter(undefined));
    }
  }

  useEffect(() => {
    dispatch(changeSelectedFilter(undefined));
    setCurrentFilter(0)
  }, [selectedSubcategory]);


  return (
    <Container fluid className="p-0">
      <div
        className="nav-wrapper nav-categories d-flex categories-view"
        style={{backgroundColor: `${colorDomain}`}}
      >
        <IconButton onClick={() => scrollBefore(menu)} className="icon-button">
          <NavigateBefore fontSize='small' />
        </IconButton>
        <Nav
          className="flex-md-row flex-xs-row menu-scroller"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <div ref={menu} className="menu-scroller">
            {(subcategories || []).map((subcategory, key) =>
              (subcategory.adult_restricted && user.age >= 18) || !user.name ? (
                <div className="mx-2" key={key} style={{ cursor: "pointer" }}>
                  <NavLink
                    aria-selected={currentSubcategory === key}
                    className={`mb-sm-3 py-2 mb-md-0 category-tab ${currentSubcategory === key ? "active" : ""
                      }`}
                    onClick={() => {
                      sendCategoryEvent([[category], [category, subcategory]]);
                      changeSubcategory(subcategory, key)
                    }}
                    role="tab"
                  >
                    {subcategory.display_name}
                  </NavLink>
                </div>
              ) : (
                <div className="mx-2" key={key} style={{ cursor: "pointer" }}>
                  <NavLink
                    aria-selected={currentSubcategory === key}
                    className={`mb-sm-3 py-2 mb-md-0 category-tab ${currentSubcategory === key ? "active" : ""
                      }`}
                    onClick={() => {
                      sendCategoryEvent([[category], [category, subcategory]]);
                      changeSubcategory(subcategory, key)
                    }}
                    role="tab"
                  >
                    {subcategory.display_name}
                  </NavLink>
                </div>
              )
            )}
          </div>
        </Nav>
        <IconButton onClick={() => scrollNext(menu)} className="icon-button">
          <NavigateNext fontSize='small' />
        </IconButton>
      </div>
      {filters && filters.length > 2 && (
        <div className="nav-wrapper nav-categories">
          <Nav
            className="flex-md-row flex-xs-row menu-scroller"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            {(filters || []).map((filter, key) => (
              <NavItem className="mx-2" key={key} style={{ cursor: "pointer" }}>
                <NavLink
                  aria-selected={currentFilter === key}
                  style={{
                    borderBottomColor: `${colorDomain}`,
                    boxShadow: "none",
                  }}
                  className={`mb-sm-3 py-2 mb-md-0 filter-tab ${currentFilter === key ? "active" : ""
                    }`}
                  onClick={() => {
                    sendCategoryEvent([
                      [category],
                      [category, selectedSubcategory],
                      [category, selectedSubcategory, filter],
                    ]);
                    changeFilter(filter, key);
                  }}
                  role="tab"
                >
                  {filter.display_name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      )}
    </Container>
  );
}

export default SubcategoryTabs;
