import { applyProductFunctions } from "./models/product";
import { notification } from "./util";

export function getSubcategorie(categories, section_id) {
  return categories.find((cat) => cat.id === section_id)
    ? categories.find((cat) => cat.id === section_id).display_name
    : "Extras";
}

export function checkIsNewCategory(products, product, key) {
  if (key > 0) {
    if (!product.is_highlight) {
      return (
        (product.section_id === products[key + 1].section_id &&
          product.section_id !== products[key - 1].section_id) ||
        (product.section_id !== products[key + 1].section_id &&
          product.section_id !== products[key - 1].section_id)
      );
    } else {
      return (
        product.is_highlight !== products[key - 1].is_highlight &&
        product.is_highlight === products[key + 1].is_highlight
      );
    }
  } else {
    return true;
  }
}

export function addSubcategoryAllFilter(subcategories) {
  // Define uma subcategoria com as propriedades necessárias
  const allProductsSubCategory = {
    adult_restricted: false,
    bulk: false,
    display_name: "Todos",
    filter: false,
    id: null,
    name: "Todos",
  };

  // Cria um novo array com as subcategorias recebidas como parâmetro
  // E adiciona uma opção "Todos" em cada uma que possuir subcategorias
  const subCategoriesWithAllFilter = subcategories.map((subcat) => {
    if (subcat.subcategories) {
      // Define uma nova subcategoria "Todos" para ser adicionada
      const allFilter = {
        display_name: "Todos",
        id: null,
      };

      // Cria um novo array com a opção "Todos" e as demais subcategorias
      const filtersWithAllFilter = [allFilter, ...subcat.subcategories];

      // Retorna a subcategoria original com a opção "Todos" adicionada
      return { ...subcat, subcategories: filtersWithAllFilter };
    }
    // Retorna a subcategoria original sem nenhuma alteração
    return subcat;
  });

  // Adiciona a subcategoria "Todos" como a primeira opção no array de subcategorias
  const subCategories = [allProductsSubCategory, ...subCategoriesWithAllFilter];

  // Retorna a lista de subcategorias com a opção "Todos" adicionada
  return subCategories;
}

export function addForYouCategory(categories) {
  const forYouCategory = {
    id: "paravoce",
    display_name: "Para Você",
    color: "",
    bulk: false,
    filter: false,
    name: "Para Você",
  };

  return [forYouCategory, ...categories];
}

export function hideForYouCategory(category) {
  const customer_id = localStorage.getItem("USER_ID");
  if (category.id === "paravoce" && !customer_id) {
    notification(
      `Faça login ou seu primeiro pedido para acessar a categoria "Para Você"`
    );

    return true;
  }
  return false;
}

export function filterItemsByCategory(items, category) {
  const filteredItems = items.filter((item) => item.section_id === category.id);
  return applyProductFunctions(filteredItems);
}