import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { Box, List, useMediaQuery } from "@material-ui/core";
import WidgetsIcon from "@material-ui/icons/Widgets";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import InfoIcon from "@material-ui/icons/Info";
import HomeIcon from "@material-ui/icons/Home";
import ItemList from "./ItemList";

import { useStylesSidebar } from "assets/useStyles";
import { domainColorStyle, getDomainName, getMenu } from "config";
import { changeSelectedCategory, changeSelectedSubcategory, changeSelectedFilter } from "actions/categoriesActions";
import { sendCategoryEvent } from "helpers/apis/impulseEventsApiHelper";
import AppNotices from "components/AppNotices/AppNotices";
import './SideBarList.scss'
import { getMarketCategories, getMarketCategorieSubcategories } from "helpers/apis/marketInfo";
import { categoriesChanged } from "actions/marketStuffActions";
import { addSubcategoryAllFilter } from "helpers/categoriesHelper";
import { addForYouCategory, hideForYouCategory } from "helpers/categoriesHelper";
import { getDomainLogo } from "config";
import { subcategoriesChanged } from "actions/marketStuffActions";
import { categoryProductsChanged } from "actions/productsActions";
import { subcategoryProductsChanged } from "actions/productsActions";
import { filterProductsChanged } from "actions/productsActions";
import { getFlipBooks } from "helpers/apis/flipbooksApi";
import { TEST_MODE } from "config";
import StorefrontIcon from '@material-ui/icons/Storefront';
import ChangeBrand from "new/components/ChangeBrand/ChangeBrand";



function SideBarList({
  currentMarket,
  changeSelectedCategory,
}) {

  const classes = useStylesSidebar();
  const history = useHistory();
  const [isValidFlipbooks, setIsValidFlipbooks] = useState(false);
  const deliveryMethod = useSelector(({deliveryMethod}) => deliveryMethod.deliveryMethod)
  const [changeBrand, setChangeBrand] = useState(false)
  const { market_id, category } = useParams();
  const [notice, setNotice] = useState(null);
  
  const dispatch = useDispatch()
  const categories = useSelector(({categories}) => categories.categories)

  const selectedCategory = useSelector(({ categories }) => categories.selectedCategory);
  const brand = useSelector(({ brand }) => brand?.brand);
  //podemos ate deixar com o club
  const isMenu = getMenu()
  const categoryFilterMenu = !isMenu ? categories : categories.filter((category) => category.id !== "paravoce") 
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const validateLocation = (location) => {
    const currentLocation = history.location.pathname
    switch (location) {
      case "home":
        return currentLocation === `/loja/${market_id}`
      case "offers":
        return currentLocation === `/loja/${market_id}/ofertas`
      case "flipbooks":
        return currentLocation === `/loja/${market_id}/encartes`
      default:
        break
    }
  }
  
  const getCategories = async (marketID) => {
    const { categories } = await getMarketCategories(marketID);
    const updatedCategories = addForYouCategory(categories)
    dispatch(categoriesChanged(updatedCategories));
  }

  const getCategorySubcategories = async () => {
    const { subcategories } = await getMarketCategorieSubcategories(market_id, category);
    const updatedSubcategories = addSubcategoryAllFilter(subcategories);
    dispatch(subcategoriesChanged(updatedSubcategories));
  }

  function watchCategory() {
    if (!category) {
      dispatch(changeSelectedCategory(undefined));
      dispatch(subcategoriesChanged(undefined));
      dispatch(changeSelectedSubcategory(undefined));
      dispatch(changeSelectedFilter(undefined))
      dispatch(categoryProductsChanged([]))
      dispatch(subcategoryProductsChanged([]))
      dispatch(filterProductsChanged([]))
    }
  }
  
  useEffect(() => {
    if (market_id) getCategories(market_id)
  }, [market_id])

  useEffect(() => {
    watchCategory();
  }, [category]);

  useEffect(() => {
    if (selectedCategory) getCategorySubcategories();
  }, [selectedCategory]);

  const changeCategory = async (category) => {
    if (hideForYouCategory(category)) {
      return;
    }

    if (!category.id) {
      dispatch(changeSelectedCategory(undefined));
      history.push(`/loja/${market_id}`);
      return;
    }

    if (category.id !== selectedCategory?.id) {
      dispatch(changeSelectedCategory(category));
      sendCategoryEvent([[category]]);
      history.push(`/loja/${market_id}/categoria/${category.id}`);
      if (category.bulk) setNotice(true);
    }
  };

  const getAllFlipbooks = async () =>  {
    const market = currentMarket.id || market_id;
    const { flipbooks } = await getFlipBooks(market);
    if (flipbooks.length) {
      setIsValidFlipbooks(true)
    }
  }

  useEffect(() => {
    getAllFlipbooks();
  }, [currentMarket, market_id]);

  return (
    <>
      <Box className="market-info" >
        {isSmallScreen ? (
          <img
            src={getDomainLogo()}
            alt={getDomainName()}
            onClick={() => history.push(`/loja/${market_id}`)}
          />
        ) : null}
      <Link to={`/loja/${market_id}`}>
        <p className={classes.drawerTitle}>
          {currentMarket.name || getDomainName()}
        </p>
      </Link>

        {deliveryMethod ? (
          <span
          className="side-delivery-method-text"
            style={{ ...domainColorStyle("color") }}
          >
          {deliveryMethod.display_name}
          </span>
        ) : null}

      <Link
        to={"/informacoes"}
        className="side-link-info"
          style={{ ...domainColorStyle("color") }}
      >
        <span className="link-text-info">
          <InfoIcon
            fontSize="small"
            style={{ fontSize: 18, marginRight: 5 }}
            />
          Informações da loja
        </span>
      </Link>
      </Box>

      <div className={classes.drawerContainer}>
        <List style={{ paddingTop: 0 }}>
          {TEST_MODE ? (
            <ItemList
              isSelected={changeBrand}
              title="Alterar Marca"
              handleClick={() => setChangeBrand(true)}
              icon={<StorefrontIcon />}
            />
          ) : null}
          {isValidFlipbooks &&
            <ItemList
              isSelected={validateLocation("flipbooks")}
              title="Encartes"
              handleClick={() => history.push(`/loja/${market_id}/encartes`)}
              icon={<BookmarkIcon />}
            />
          }
          <ItemList
            isSelected={validateLocation("home")}
            title="Início"
            handleClick={() => history.push(`/loja/${market_id}`)}
            icon={<HomeIcon />}
          />
          <ItemList
            isSelected={validateLocation("offers")}
            title="Ofertas"
            handleClick={() => history.push(`/loja/${market_id}/ofertas`)}
            icon={<LocalOfferIcon />}
          />
          {brand?.crm && !isMenu &&(
            <ItemList
              isSelected={selectedCategory && selectedCategory.id === "clube"}
              title={brand.crm.club}
              handleClick={() => changeCategory({ id: "clube" })}
              icon={<LocalOfferIcon />}
            />
          )}
          {categoryFilterMenu.map((category, index) => (
            <ItemList
              key={index}
              title={category.display_name}
              isSelected={
                selectedCategory && selectedCategory.id === category.id
              }
              handleClick={() => changeCategory(category)}
              icon={<WidgetsIcon />}
            />
          ))}
        </List>
      </div>
      {notice && (
        <AppNotices
          isOpen={notice}
          toggleModal={() => setNotice(false)}
          notice={{
            description:
              "Essa seção contém produtos vendidos por peso. As quantidades pedidas nesse tipo de produto correspondem a um peso aproximado, o que pode acarretar em variações no total do pedido.",
          }}
          title={"Aviso"}
        />
      )}
      <ChangeBrand open={changeBrand} setOpen={setChangeBrand} />
    </>
  );
}

function mapStateToProps({ categories, markets, settings }) {
  return {
    categories: categories.categories,
    selectedCategory: categories.selectedCategory,
    currentMarket: markets,
    brand: settings.brand,
  };
}
export default connect(mapStateToProps, { changeSelectedCategory })(
  SideBarList
);