import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/app";
import { StyledFirebaseAuth } from "react-firebaseui";
import Messages from "components/ChatOrder/Messages";

import { domainColorStyle } from "config";
import { getChat } from "helpers/apis/chatApi";
import { sendNewMessage } from "helpers/apis/chatApi";
import { notification } from "helpers/util";

import "../../assets/css/react-confirm-alert.css";
import "./Chat.scss";
import { updateStyled } from "helpers/util";
import getOneSignalPlayerId from "helpers/oneSignalHelper";

import { useSelector } from "react-redux";
import { UserModal } from "new/components/UserPage/UserModal/UserModal";

import ButtonBackTop from "new/components/Button/ButtonBackTop/ButtonBackTop";
import { getUiConfig } from "enums/uiConfig";
import { getCustomerByCpf } from "helpers/apiHelper";


function Chat() {
  const [showToggle, setShowToggle] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [has_general_chat, setHasChat] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [chatId, setChatId] = useState(null);
  const [phone, setPhone] = useState("");
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const [uiConfig, setUiConfig] = useState(null)
  const brand = useSelector(({ brand }) => brand.brand)

  const authObserver = useCallback(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !customer) {
        setPhone(user.phoneNumber?.split("+55").join(""));
      }
    });
  }, [customer]);

  const getChatGeral = useCallback(async () => {
    if (brand.has_general_chat) {
      setHasChat(true);
      let player_id = await getOneSignalPlayerId();
      const chat = await getChat(player_id, customer.name, customer.phone, 3);
      if (chat && chat.chat_id) {
        const socket = new WebSocket(
          `wss://merconnect.mercadapp.com.br/websockets/chats/${chat.chat_id}`
        );
        socket.onmessage = (menssage) => {
          setChatMessages(JSON.parse(menssage.data));
          setChatId(chat.chat_id);
        };
      }
    }
  }, [customer]);

  async function getAuthConfig(brand) {
    const response = await getUiConfig(brand)
    setUiConfig(response)
  }

  useEffect(() => {
    getAuthConfig(brand);
  }, []);

  useEffect(() => {
    if (userInfo?.id !== (undefined || null)) {
      findCustomerById();
    } else {
      firebase.auth().signOut();
    }
  }, [userInfo]);

  useEffect(() => {
    if (customer) {
      getChatGeral();
    } else {
      getBrand();
      authObserver();
    }
  }, [customer]);

  async function getBrand() {
    if (brand.has_general_chat) {
      setHasChat(true);
    }
  }

  const findCustomerById = () => {
    const id = localStorage.getItem("USER_ID");
    if (id !== null) {
      getCustomerByCpf((error, data) => {
        if (error) {
          console.log(error);
        } else {
          setCustomer(data.customer);
        }
      });
    } else {
      return;
    }
  };

  const sendMessage = () => {
    try {
      sendNewMessage(chatId, newMessage);
      setNewMessage("");
    } catch {
      notification("Ocorreu um erro ao enviar sua mensagem.", "danger");
    }
  };

  return has_general_chat ? (
    <>
      {showToggle ? (
        <section className="floating-button-right chat-floating-button-right">
          <span className="span-chat">Fale conosco</span>
          <div
            color="secondary"
            style={{
              ...domainColorStyle("background"),
              height: "80px",
              width: "80px",
              borderRadius: "10pc",
            }}
            className="button"
            onClick={() => setShowToggle(false)}
          >
            <span className="nav-link-inner--icon">
              <i
                style={{ fontSize: 35 }}
                className="far text-white text-exclamation fa-comments mr-1 icon-chat"
              />
            </span>
          </div>
          <ButtonBackTop />
        </section>
      ) : (
        <div className="floating-button-right chat-floating-button-right chat-menu shadow">
          {customer ? (
            <div className="text-center h-100 w-100">
              <div
                className="header-chat"
                style={domainColorStyle("background")}
              >
                <p>Fale conosco!</p>
                <button onClick={() => setShowToggle(true)}>x</button>
              </div>
              <div
                style={{
                  marginTop: "3.1rem",
                  overflow: "auto",
                  height: "calc(100% - 3.1rem)",
                }}
              >
                <Messages chatMessages={chatMessages} />
                <div className="input-chat">
                  <input
                    onKeyDown={({ key }) =>
                      key === "Enter" ? sendMessage() : null
                    }
                    value={newMessage}
                    onChange={({ target }) => setNewMessage(target.value)}
                  />
                  <button onClick={() => sendMessage()}>
                    <i
                      className=" far text-exclamation fa-paper-plane mr-1"
                      style={{ ...domainColorStyle("color"), fontSize: 25 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <section className="section">
              <div
                className="header-chat"
                style={domainColorStyle("background")}
              >
                <p>Fale conosco!</p>
                <button onClick={() => setShowToggle(true)}>x</button>
              </div>
              <StyledFirebaseAuth
                className="section t-5 shadow-none"
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
              {updateStyled()}
            </section>
          )}
        </div>
      )}
      {phone && (
        <UserModal isOpen={true} phone_number={phone} />
      )}
    </>
  ) : (
    <></>
  );
}

export default React.memo(Chat);
