import _ from "lodash";

export function productsArrayFromMix(mix) {
  return _.map(mix, (product, id) => product);
}

export function productsArrayFromMixes(mixes) {
  let products = [];

  _.forEach(mixes, (mix, id) => {
    _.forEach(mix, (product, id) => {
      products.push(product);
    });
  });

  return products;
}

export function orderFormattedProductsArrayFromMixes(mixes, productAmounts) {
  const products = productsArrayFromMixes(mixes);

  const orderProducts = _.map(products, (product) => {
    return {
      product_id: product.id,
      price: product.currentPrice(),
      amount: productAmounts[product.id],
      // No futuro, quando tiver as promoções, colocar o total_price
    };
  });

  return orderProducts;
}

export function productsMappedWithIdsFromMixes(mixes) {
  const products = productsArrayFromMixes(mixes);
  return _.mapKeys(products, "id");
}

export function mixesArrayWithSubcategoryIdFromMixes(
  mixes,
  subcategoryId,
  filterId
) {
  let mixesOfCategory = mixes.filter(
    (mix) =>
      // Se não tiver categoryId é porque é para mostrar todos,
      // se tiver categoryId comparamos com algum produto do mix.
      // O category_id no servidor é o mesmo que o subcategoryId é aqui.
      !subcategoryId || (mix || {}).category_id === subcategoryId
  );

  if (filterId) {
    mixesOfCategory = mixesOfCategory.filter(
      (mix) =>
        // Se não tiver categoryId é porque é para mostrar todos,
        // se tiver categoryId comparamos com algum produto do mix.
        // O category_id no servidor é o mesmo que o subcategoryId é aqui.
        (mix || {}).filter_category_id === filterId
    );
  }
  
  return mixesOfCategory;
}

export function mixesTotalCost(mixes, productAmounts) {
  let allCosts = productsArrayFromMixes(mixes).map((product) => {
    return product.currentPrice() * (productAmounts[product.id] || 0);
  });

  return _.reduce(
    allCosts,
    function (costSum, cost) {
      return costSum + cost;
    },
    0
  );
}

// Adiciona um produto a um mix (cria o mix se necessário).
// Se o produto já existir, o atualiza.
export function addProductInMixes(product, mixes) {
  // Checa se mix_id é "falsy", exceto 0, já que pode existir um mix_id 0.
  // OBS: Por algumo motivo entrava aqui um produto sem mix_id. Não podemos permitir isso.
  if (!product.mix_id && product.mix_id !== 0) return;

  if (!mixes.hasOwnProperty(product.mix_id)) mixes[product.mix_id] = {};

  mixes[product.mix_id][product.id] = product;
}

// Remove um produto de um mix.
// Se não restar nenhum produto, apaga o mix inteiro.
export function deleteProductFromMixes(product, mixes) {
  if (!mixes[product.mix_id]) return;
  delete mixes[product.mix_id][product.id];
  if (Object.keys(mixes[product.mix_id]).length === 0) {
    delete mixes[product.mix_id];
  }
}

export function groupProductsByMixId (item, items) {
  const { mix_id } = item
  const itemsFromMix = items.filter(item => item.mix_id === mix_id)
  if (itemsFromMix.length > 1) {
    return itemsFromMix
  } 
}

// Filtra array de items mantendo apenas a primeira ocorrência de cada mix_id
export function removeRepeatedMixes(items) {
  const firstItemsOfEachMix = items.reduce((acc, item) => {
    // Verifica se o item atual (item) já está presente no array acumulador (acc) com base no mix_id
    // Se não estiver presente, adiciona o item atual (item) ao array acumulador (acc)
    if (!acc.some((i) => i.mix_id === item.mix_id)) {
      acc.push(item);
    }
    // Retorna o array acumulador (acc) após cada iteração
    return acc;
  }, []);

  // Retorna o array firstItemsOfEachMix contendo apenas a primeira ocorrência de cada mix_id dos itens originais
  return firstItemsOfEachMix;
}



