import React, { useState, useEffect, useCallback } from "react";
import { Fade } from "react-reveal";
import SubcategoryTabs from "../../SubcategoryTabs";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Container } from "reactstrap";
import NewList from "../NewList/NewList";
import ContainerProducts from "./ContainerProducts";
import ChatFirebase from "components/ChatFirebase/Chat";
import Chat from "components/Chat/Chat";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import ButtonBackTop from "new/components/Button/ButtonBackTop/ButtonBackTop";
import "./ProductsView.scss";
import { applyProductFunctions } from "helpers/models/product";
import { ordenationChanged } from "actions/uiActions";
import { saveBrand } from "actions/configsActions";
import { getUseLegacyChat } from "../../../firebase";
import ProductsSorter from "../ProductsSorter/ProductsSorter";
import { getMenu } from "config";

function ProductsView() {
  const [showModal, setShowModal] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const currentCategory = useSelector(({ categories }) => categories.selectedCategory) || {}
  const subcategories = useSelector(({ categories }) => categories.subcategories) || []
  const selectedFilter = useSelector(({ categories }) => categories.selectedFilter)
  const selectedSubcategory = useSelector(({ categories }) => categories.selectedSubcategory)
  const filters = useSelector(({ categories }) => categories.selectedSubcategory?.subcategories)
  const brand = useSelector(({ brand }) => brand.brand)
  const hasAutoCoupons = useSelector(({ settings }) => settings.hasAutoCoupons)

  const searchActive = useSelector(({ ui }) => ui.searchActive)

  const currentMarket = useSelector(({ markets }) => markets)

  const categoryProducts = useSelector(({ products }) => products.categoryProducts)
  const subcategoryProducts = useSelector(({ products }) => products.subcategoryProducts)
  const filterProducts = useSelector(({ products }) => products.filterProducts)
  const searchProducts = useSelector(({ products }) => products.searchProducts)
  const isValidMenu = getMenu()

  const dispatch = useDispatch();
  const showSubCategory = selectedSubcategory && (filters || []).length > 2;

  const getProductsToShow = () => {
    switch (true) { 
      case Boolean(searchActive):
        return applyProductFunctions(searchProducts)
      case Boolean(selectedFilter?.id):
        return applyProductFunctions(filterProducts)
      case Boolean(selectedSubcategory?.id):
        return applyProductFunctions(subcategoryProducts)
      default:
        return applyProductFunctions(categoryProducts) 
    }
  }

  const getBrand = useCallback(async () => {
    if (brand) {
      dispatch(saveBrand(brand))
    }
  }, [dispatch])

  const handleFooter = () => {
    if (window.scrollY > 1500 && !showFooter) {
      setShowFooter(true);
    } else if (window.scrollY < 1500 && showFooter) {
      setShowFooter(false);
    }
  };

  useEffect(() => {
    getBrand();
  }, []);


  useEffect(() => {
    dispatch(ordenationChanged("1"));
  }, [currentCategory, selectedSubcategory, selectedFilter]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const valueMargin = () => {
    const newValue = !!(scrollPosition < 50 && hasAutoCoupons)
    if (newValue) {
      return "-6px";
    } else {
      return "-44px";
    }
  };

  return (
    <>
      {((subcategories || []).length > 0 &&
        currentCategory.id !== undefined &&
        !searchActive) && (
          <Container

            style={{
              position: "fixed",
              zIndex: 1000,
              background: "#f4f4f4",
              marginTop: `${isValidMenu ? "-42px" : hasAutoCoupons ? valueMargin() : "-3px"}`,
            }}
            fluid className="p-0"
          >
            <Fade left>
              <SubcategoryTabs
                category={currentCategory}
              />
            </Fade>
          </Container>
        )}
      {!searchActive && (
        <Box
          className="d-flex"
          justifyContent="flex-end"
          style={{ maxWidth: "100vw", margin: `${showSubCategory ? 115 : 65}px 15px 10px` }}
        >
          {getProductsToShow().length > 0 &&
            <Col
              xs={12}
              lg={4}
              className="px-1 px-lg-4 order-products"
            >
              {currentCategory?.id ? (
                <ProductsSorter />
              ) : null}
            </Col>}
        </Box>
      )}

      <div>
        <InfiniteScroll
          scrollThreshold={"200px"}
          dataLength={(getProductsToShow() || []).length}
          onScroll={handleFooter}
        >
          <ContainerProducts products={getProductsToShow()} currentCategory={currentCategory} />
        </InfiniteScroll>

        {showModal && (
          <NewList
            showModal={showModal}
            title={"Listas"}
            toggleModal={setShowModal}
            currentMarket={currentMarket}
          />
        )}
        {getUseLegacyChat() ? (
          <Chat setShowModal={setShowModal} market={currentMarket} />
        ) : (
          <ChatFirebase
            setShowModal={setShowModal}
            marketId={currentMarket.id}
          />
        )}
        {brand && !brand.has_general_chat ? (
          <div className="button-back-top button-back-top-secundary">
            <ButtonBackTop />
          </div>
        ) : (<></>)}
      </div>
    </>
  );
}

export default ProductsView;
