import { getForYouProducts, getFreeDeliveryProducts } from "./apiHelper";
import { getMarketItemsBySection } from "./apis/marketInfo";
import { mixesArrayWithSubcategoryIdFromMixes } from "./mixesHelper";
import { notification } from "./util";

export function order(a, b, ordenation) {
  switch (ordenation) {
    case "1":
      if (a.description === b.description) return 0;
      return a.description.trim() > b.description.trim() ? 1 : -1;
    case "2":
      if (a.description === b.description) return 0;
      return a.description.trim() < b.description.trim() ? 1 : -1;
    case "3":
      return b.currentPrice() - a.currentPrice();
    case "4":
      return a.currentPrice() - b.currentPrice();
    default:
      if (a.description === b.description) return 0;
      return a.description > b.description ? 1 : -1;
  }
}

export function sectionItens(
  products,
  categories,
  selectedCategory = {},
  ordenation
) {
  const keys =
    selectedCategory?.id === undefined
      ? ["is_highlight", "section_id", "description"]
      : ["section_id"];

  if (selectedCategory?.id !== undefined) {
    return products.sort((a, b) => order(a, b, ordenation));
  }

  return products.sort((a, b) => {
    for (let key of keys) {
      if (a[key] === b[key]) continue;

      if (key === "is_highlight") {
        return a.is_highlight ? -1 : 0;
      }

      if (key === "id") {
        if (a.is_highlight && b.is_highlight) {
          return order(a, b, ordenation);
        }

        if (!categories.find((cat) => cat.id === a.id)) {
          return "Extras" >
            categories.find((cat) => cat.id === b.id).display_name
            ? 1
            : -1;
        } else if (!categories.find((cat) => cat.id === b.id)) {
          return categories.find((cat) => cat.id === a.id).display_name >
            "Extras"
            ? 1
            : -1;
        }
        return 1;
      }

      if (key === "description") {
        return order(a, b, ordenation);
      }

      return a[key] > b[key] ? 1 : -1;
    }

    return 0;
  });
}


export function getMixesFromSubcategoryAndFilter(
  mixes,
  selCat = {},
  selSubcat = {},
  selFilter = {}
) {
  // recebe um array de mixes, categoria, subcategoria e filtro

  // se o id da categoria for undefined, retorna o mesmo array de mixes
  if (!selCat.id) {
    return mixes;
  }

  // se houver uma categoria, organiza os mixes de acordo com a subcategoria e filtro
  return mixesArrayWithSubcategoryIdFromMixes(
    mixes,
    selSubcat.id,
    selFilter.id
  );

}

export function getMixesOrdered(
  selCat = {},
  selSubcat = {},
  selFilter = {},
  mixes,
  cats,
  order
) {
  const curMixes = getMixesFromSubcategoryAndFilter(
    mixes,
    selCat,
    selSubcat,
    selFilter
  );

  // recebe os mixes organizados e os retorna ordenados de acordo com a opção escolhida
  return sectionItens(curMixes, cats, selCat, order);
}


async function fetchMixes(marketID, section, deliveryMethodID, neighborhoodId) {
  // a função retorna um obj contendo os mixes de produtos que serão mostrados
  const customerID = localStorage.getItem("USER_ID");

  // caso seja a categoria "Para Você" e houver usuário logado, retorna os produtos da categoria "Para Você"
  if (section === "paravoce" && customerID) {
    return await getForYouProducts(marketID, customerID, null, neighborhoodId );
  }

  if (section === "fretegratis") {
    return await getFreeDeliveryProducts(marketID, deliveryMethodID, customerID, neighborhoodId)
  }
  // para qualquer outra categoria, retorna os produtos da mesma
  return await getMarketItemsBySection(marketID, section, deliveryMethodID, null, neighborhoodId);
} 


export function extractItemsFromMixes(mixes) {
  if (!mixes || !mixes.mixes) {
    return [];
  }
  // recebe um obj com mixes e extrai dele todos os produtos para um único array 
  return mixes.mixes.flatMap((mix) => mix.items);
}


export async function organizeMixes(marketID, section, deliveryMethodID, neighborhoodId) {
  // recebe os mixes atuais de acordo com market e section e retorna um array com seus produtos
  const mixes = await fetchMixes(marketID, section, deliveryMethodID, neighborhoodId);
  return extractItemsFromMixes(mixes);
}

export async function fetchCategoryItems(marketID, section, deliveryMethodID, neighborhoodId) {
  try {
    return await organizeMixes(marketID, section, deliveryMethodID, neighborhoodId);

  } catch (error) {
    console.log(error);
    notification(
      "Ocorreram erros, verifique sua conexão com a internet e tente novamente!",
      "danger"
    );
  }
}

export const dispatchItemsInBatches = (items, dispatchFunc) => {
  if (!items || items.length === 0) {
    dispatchFunc([]);
    return;
  }

  const batchSize = 25;
  const initialBatchCount = 3;
  const initialBatchInterval = 250;
  const lastBatchInterval = 1000;
  const maxRemainingItemsLength = 400;
  let startIndex = 0;
  let itemsToDispatch = [];

  const dispatchInitialBatch = () => {
    const fastBatch = items.slice(startIndex, batchSize + startIndex);
    itemsToDispatch = [...itemsToDispatch, ...fastBatch];
    startIndex += batchSize;
    dispatchFunc(itemsToDispatch);

    if (startIndex < items.length && startIndex < initialBatchCount * batchSize) {
      setTimeout(dispatchInitialBatch, initialBatchInterval);
    } else {
      setTimeout(dispatchRemainingItems, lastBatchInterval);
    }
  };

  const dispatchRemainingItems = () => {
    const remainingItems = items.slice(startIndex);
    
    if (remainingItems.length > maxRemainingItemsLength) {
      const firstBatch = remainingItems.slice(0, maxRemainingItemsLength);
      const secondBatch = remainingItems.slice(maxRemainingItemsLength);
      
      itemsToDispatch = [...itemsToDispatch, ...firstBatch];
      dispatchFunc(itemsToDispatch);
      
      setTimeout(() => {
        itemsToDispatch = [...itemsToDispatch, ...secondBatch];
        dispatchFunc(itemsToDispatch);
      }, lastBatchInterval);
    } else {
      itemsToDispatch = [...itemsToDispatch, ...remainingItems];
      dispatchFunc(itemsToDispatch);
    }
  };

  dispatchInitialBatch();
};



