import React, { useEffect, useState } from "react";

import { ModalBody, Modal, Button } from "reactstrap";

import { domainColorStyle } from "config";

import "./ConfirmTermsModal.scss";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useDispatch, useSelector } from "react-redux";
import { userInfoChanged } from "actions/userInfoActions";
import { showNotification } from "helpers/util";
import { updateCustomer } from "helpers/apiHelper";

function ConfirmTermsModal({
  privacyPolicy,
  isOpen,
  toggleModal
}) {
  const [able, setAble] = useState(false);
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const brand = useSelector(({brand}) => brand.brand)
  const currentUseTermsVersion = userInfo.terms_of_use_version
  const dispatch = useDispatch()
  useEffect(() => {
    if (privacyPolicy?.version === currentUseTermsVersion) {
      dispatch(userInfoChanged({ ...userInfo, terms_of_use_version: currentUseTermsVersion }))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIXME: Isso buga quando o chrome exibe a barra de downloads
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setAble(true);
    } else if (able) {
      setAble(false);
    }
  };

  const updateCustomerOnServer = (customerData, newVersion) => {
    const customerWithoutPhone = { ...customerData, phone: undefined }
    updateCustomer(customerWithoutPhone, brand.id, (error, data) => {
      if (error) {
        console.log(error);
        showNotification(
          "top",
          "center",
          "Falha ao salvar seus dados.",
          "error"
        )
      } else {

        localStorage.setItem("USER_ID", data.customer.id);
        dispatch(userInfoChanged({ ...userInfo, terms_of_use_version: newVersion }))
        toggleModal()
      }
    });
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleModal()}
      className="modal-dialog-centered modal-terms-policy"
    >
      <ModalBody className="modal-terms-body">
        <div
          onScroll={handleScroll}
          style={{ overflow: "scroll", height: "60vh" }}
        >
          <p>{privacyPolicy?.text}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            dispatch(userInfoChanged({ ...userInfo, terms_of_use_version: currentUseTermsVersion }))

            toggleModal();
          }}
          style={{ color: "#fff", backgroundColor: "#db0000" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => updateCustomerOnServer({ ...userInfo, terms_of_use_version: currentUseTermsVersion }, privacyPolicy?.version)}
          style={{ color: "#fff", ...domainColorStyle("backgroundColor") }}
        >
          Aceitar Termos
        </Button>
      </ModalFooter>
    </Modal >
  );
}

export default ConfirmTermsModal;
