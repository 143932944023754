import { getAuthMethods } from "helpers/activeAuthMethods";

export async function getUiConfig(brand) {
  const response = await getAuthMethods(brand);

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: response,
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  return uiConfig;
}