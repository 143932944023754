import { domainColorStyle } from "config";
import { sendCustomerInfoEvent } from "helpers/analyticHelper";
import { userInfoChanged } from "actions/userInfoActions";
import "./UserPage.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from "reactstrap";
import "./UserPage.scss";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import LoadingContent from "components/LoadingContent";
import UserForm from "components/UserPage/UserForm/UserForm";
import OrdersList from "components/UserPage/OrdersList/OrdersList";
import ShoppingLists from "components/UserPage/ShoppingLists/ShoppingLists";
import NotificationCenter from "new/components/UserPage/NotificationCenter/NotificationCenter";
import CardLIstingMenu from "new/components/UserPage/PaymentCardListing/CardLIstingMenu/CardLIstingMenu";
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { UserModal } from "new/components/UserPage/UserModal/UserModal";
import { getUiConfig } from "enums/uiConfig";
import { getCustomerByCpf } from "helpers/apiHelper";
import { useHistory } from "react-router-dom";
import Coupons from "components/UserPage/Cupom/Coupons";
import FriendCampaign from "components/UserPage/FriendCampaign/FriendCampaign";
import UserLoginModal from "components/UserLoginModal/UserLoginModal";
import { getDomainMarketColor } from "config";

const UserPage = ({ location }) => {
  const userInfo = useSelector(({ userInfo }) => userInfo);
  const brand = useSelector(({ brand }) => brand.brand);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sections, setSections] = useState([
    "Dados cadastrais",
    "Central de Cupons",
    "Pedidos",
    "Cartões",
    "Listas de Compras",
    "Central de Notificações",
  ]);
  const [loading, setLoading] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [uiConfig, setUiConfig] = useState(null);
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState(null);
  const userId = userInfo.id || localStorage.getItem("USER_ID");
  const showFirebaseOptions = Boolean(
    !signedIn && !loading && uiConfig && uiConfig?.signInOptions.length
  );

  const iconMap = {
    "Dados cadastrais": <PersonIcon />,
    "Central de Cupons": <ConfirmationNumberIcon />,
    "Pedidos": <ReceiptIcon />,
    "Cartões": <CreditCardIcon />,
    "Listas de Compras": <ListAltIcon />,
    "Central de Notificações": <NotificationsIcon />
  }

  useEffect(() => {
    if (brand) getAuthConfig(brand);
    authObserver();
    handleRedirectTab();
  }, []);

  useEffect(() => {
    userId && findCustomer();
  }, [userId]);

  const handleRedirectTab = () => {
    if (window.location.pathname === "/user/cupons") {
      setActiveTabIndex(1);
    }
    if (location.state?.my_orders) {
      setActiveTabIndex(2);
    }
  };

  const getAuthConfig = async (brand) => {
    try {
      const response = await getUiConfig(brand);
      setUiConfig(response);
    } catch (error) {
      throw error;
    }
  };

  const authObserver = () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setSignedIn(true);
        setTimeout(()=> {
          setFirebaseRecoveredInfo({
            name: user?.displayName,
            phone_number: user?.phoneNumber?.split("+55").join(""),
            email: user?.email,
          });
        }, 1000)
        sendCustomerInfoEvent();
      }
      setLoading(false);
    });
  };

  const removeSectionByName = (sectionName) => {
    setSections(sections.filter((section) => section !== sectionName));
  };

  const findCustomer = () => {
    setLoading(true);
    getCustomerByCpf((error, data) => {
      if (error) {
        console.log(error);
      } else {
        const { name, cpf, email, birth_date, phone_number } = data.customer;
        localStorage.setItem("USERNAME", name);
        dispatch(
          userInfoChanged({
            ...userInfo,
            name,
            cpf,
            email,
            birth_date,
            phone_number,
          })
        );
      }
      setLoading(false);
    });
  };

  const toggleTab = (index) => {
    if (activeTabIndex !== index) {
      setActiveTabIndex(index);
    }
  };

  const getActiveTabStyles = (index) => {
    const mainColor = getDomainMarketColor();
    return index === activeTabIndex ? {
        color: mainColor,
        borderBottom: `2px solid ${mainColor}`,
        borderStyle: 'none none solid none'
    } : {};
};

  switch (true) {
    case loading:
      return (
        <div style={{ paddingTop: 100 }}>
          <LoadingContent isLoading={loading} />
        </div>
      );
    case showFirebaseOptions:
      return (
        <section className="section">
          <Container className="mt-5">
            <UserLoginModal
              showModal={showFirebaseOptions}/>
          </Container>
        </section>
      );
    default:
      return (
        <section className="section user-page">
          {firebaseRecoveredInfo && (
            <UserModal
              isOpen={true}
              firebaseRecoveredInfo={firebaseRecoveredInfo}
            />
          )}
          <Container className="mt-5">
            <Row className="mb-2">
              <Col xs={12} md={1}>
                <IconButton
                  style={{ ...domainColorStyle("color"), borderRadius: 5 }}
                  size="small"
                  onClick={() => history.goBack()}
                  color="primary"
                >
                  <ArrowBack
                    style={domainColorStyle("fill")}
                    fontSize="inherit"
                  />
                  Voltar
                </IconButton>
              </Col>
            </Row>
            <Nav tabs style={{ justifyContent: "space-around", marginBottom: "1.5rem" }}>
              {sections.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={
                      activeTabIndex === index ? "nav-link active" : "nav-link"
                    }
                    style={getActiveTabStyles(index)}
                    onClick={() => toggleTab(index)}
                  >
                    {iconMap[item]}
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {userId ? (
              <TabContent activeTab={activeTabIndex}>
                <TabPane tabId={0}>
                  <UserForm/>
                </TabPane>
                <TabPane tabId={1}>
                  <Coupons />
                </TabPane>
                <TabPane tabId={2}>
                  <OrdersList />
                </TabPane>
                <TabPane tabId={3}>
                  <CardLIstingMenu removeSection={removeSectionByName} />
                </TabPane>
                <TabPane tabId={4}>
                  <ShoppingLists />
                </TabPane>
                <TabPane tabId={5}>
                  <NotificationCenter />
                </TabPane>
                <TabPane tabId={6}>
                  <FriendCampaign removeSection={removeSectionByName} />
                </TabPane>
              </TabContent>
            ) : null}
          </Container>
        </section>
      );
  }
};

export default UserPage;
