import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import ReactGA from "react-ga";
import { getDomainName, getDomainGATrackingID, getDomainGTMId } from "config";
import {
  setupManifest,
  setupMetaDescription,
  setupKeywords,
  registerShowNotificationFunction
} from "helpers/util";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
registerShowNotificationFunction()
// Inicializaçaõ do Google Tag Manager
if (getDomainGTMId()) {
  const tagManagerArgs = {
    gtmId: getDomainGTMId(),
  };
  TagManager.initialize(tagManagerArgs);
}

// Inicialização do Google Analytics
// Pra entender melhor a implementação, ver o component withTracker.js

//Sentry
Sentry.init({
  dsn: "https://3c346516eca04916a8e9e89cf29d096a@o961347.ingest.sentry.io/4503902515494912",
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactGA.initialize(getDomainGATrackingID());

document.title = getDomainName();

setupManifest();
setupMetaDescription();
setupKeywords();

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
