import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import firebase from "firebase/app";
import { Box, Button } from "@material-ui/core";
import { getUiConfig } from "enums/uiConfig";
import { clearUserInfo } from "actions/userInfoActions";
import Modal from "new/components/Modal/Modal";
import "./UserLoginModal.scss"
import { getDomainLogo } from "config";
import googleIcon from "assets/img/icons/iconGoogle.png";
import emailIcon from "assets/img/icons/iconEmail.png"
import phoneIcon from "assets/img/icons/iconPhone.png"
import { getGoogleAuthenticationMethod } from "helpers/activeAuthMethods";
import EmailAuthenticationLogin from "./EmailAuthenticationLogin/EmailAuthenticationLogin";
import PhoneAuthenticationLogin from "./PhoneAuthenticationLogin/PhoneAuthenticationLogin";
import { UserModal } from "new/components/UserPage/UserModal/UserModal";
import LoadingContent from "components/Loading/Loading";
import { resetMarket } from "actions/setZoneForRequestedCep";


export default function UserLoginModal({ showModal, toggleModal, closeModal }) {
  const [uiConfig, setUiConfig] = useState(null);
  const [showModalEmailLogin, setShowModalEmailLogin] = useState(false)
  const [showModalPhoneLogin, setShowModalPhoneLogin] = useState(false)
  const brand = useSelector(({ brand }) => brand.brand)
  const dispatch = useDispatch();
  const [signedIn, setSignedIn] = useState(false)
  const history = useHistory();
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState(null)
  const [methodsAccess, setMethodsAccess] = useState({})
  const [loading, setLoading] = useState(false);
  const showFirebaseOptions = Boolean(
    !signedIn && uiConfig && uiConfig?.signInOptions.length
  );



  const authObserver = () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setTimeout(() => {
          setFirebaseRecoveredInfo({
            name: user?.displayName,
            phone_number: user?.phoneNumber?.split("+55").join(""),
            email: user?.email,
          })
        }, 1000)
        setSignedIn(!!user);
      }
      setLoading(false);
    });
  };

  async function getAuthConfig(brand) {
    const response = await getUiConfig(brand);
    setUiConfig(response);
  }

  function logout() {
    if (closeModal) {
      dispatch(clearUserInfo())
      dispatch(resetMarket());
      firebase.auth().signOut();
      toggleModal(false)
    } else {
      localStorage.clear()
      dispatch(clearUserInfo());
      firebase.auth().signOut();
      history.push("/");
      dispatch(resetMarket());
    }
  }

  useEffect(() => {
    authObserver()
    getAuthConfig(brand);
    getAuthenticationMethodsAccess(brand);
  }, []);

  const handleOptionsLogin = (options) => {
    switch (options) {
      case "email":
        setShowModalEmailLogin(true)
        break
      case "phone":
        setShowModalPhoneLogin(true)
        break
    }
  }

  const getAuthenticationMethodsAccess = async (brand) => {
    const activeMethods = brand.brand_authentication_methods.filter(
      (method) => method.is_active === true
    );
    const authenticationMethods = {};
    activeMethods.forEach((method) => {
      authenticationMethods[method.name] = method.is_active;
    });
    setMethodsAccess(authenticationMethods)
  }

  return (
    <>
      {loading &&
        <div style={{ paddingTop: 100 }}>
          <LoadingContent isLoading={loading} />
        </div>
      }
      {showFirebaseOptions &&
        <Modal
          isOpen={showModal}
          toggleModal={() => toggleModal(false)}
          className="modal-user-login"
          style={{ backgroundColor: 'none' }}>
          <Box className="client-firebase-content">
            <Button
              color="primary"
              onClick={() => logout()}
              className="button-logout-modal-user">
              Cancelar
            </Button>
            <div className="div-title-img-user-modal">
              <img src={getDomainLogo()} alt="logo" />
              <p>Seja bem-vindo</p>
              <span>Faça login para prosseguir</span>
            </div>
            <div container className="method-new-auth-buttons">
              {methodsAccess.Website &&
                <Button variant="outlined" onClick={() => handleOptionsLogin("email")}>
                  <img alt="email" src={emailIcon} />
                  Entrar com Email
                </Button>}
              {methodsAccess.Google &&
                <Button variant="outlined" onClick={getGoogleAuthenticationMethod}>
                  <img alt="google" src={googleIcon} />
                  Entrar com Google
                </Button>}
              {methodsAccess.SMS &&
                <Button variant="outlined" onClick={() => handleOptionsLogin("phone")}>
                  <img alt="phone" src={phoneIcon} />
                  Entrar com telefone
                </Button>}
            </div>
          </Box>
        </Modal>
      }
      {!showFirebaseOptions && firebaseRecoveredInfo && showModal &&
        <UserModal
          isOpen={true}
          firebaseRecoveredInfo={firebaseRecoveredInfo} />
      }

      <EmailAuthenticationLogin
        showModal={showModalEmailLogin}
        toggleModal={setShowModalEmailLogin} />
      <PhoneAuthenticationLogin
        showModal={showModalPhoneLogin}
        toggleModal={setShowModalPhoneLogin} />
    </>
  )
}
      
