import React, { Component } from "react";
import { connect } from "react-redux";
import {
  categoriesChanged,
  bannersChanged,
} from "actions/marketStuffActions";
import { clearCart } from "actions/cartActions";
import { setCRM, clearCRM } from "actions/CRMActions";
import loadsContent from "components/loadsContent";
import HomePageSkeleton from "new/containers/Home/HomePageSkeleton";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  setZoneForRequestedCep,
  setNeighborhood,
} from "actions/setZoneForRequestedCep";
import { hasAnotherMarketMix } from "helpers/cartHelper";
import ProductsView from "../components/StorePage/ProductsView/ProductsView";
import { setMarket } from "actions/setZoneForRequestedCep";
import { openDeliveryMethod } from "actions/configsActions";
import { changeSelectedCategory } from "actions/categoriesActions";
import { search } from "helpers/apis/itemApi";
import { notification } from "helpers/util";
import { getMarketById } from "helpers/apis/marketInfo";
import { getOffers } from "helpers/apis/zoomboxApi";
import BirthdayModal from "components/StorePage/BirthdayModal/BirthdayModal";
import { applyProductFunctions } from "helpers/models/product";
import { sendSearchEvent } from "helpers/apis/impulseEventsApiHelper";
import { sendEmptySearchEvent } from "helpers/apis/impulseEventsApiHelper";
import CRMPage from "new/containers/CRMPage/CRMPage";
import ProductView from "./ProductView/ProductView";
import { updateBrandCustomer } from "helpers/apiHelper";
import { userInfoChanged } from "actions/userInfoActions";
import NotificationCenterWarningModal from "components/NotificationCenterWarningModal/NotificationCenterWarningModal";
import { fetchCategoryItems } from "helpers/storeHelper";
import { getMixesOrdered } from "helpers/storeHelper";
import { extractItemsFromMixes } from "helpers/storeHelper";
import debounce from "lodash/debounce";
import { categoryProductsChanged, subcategoryProductsChanged, filterProductsChanged } from "actions/productsActions";
import { searchProductsChanged } from "actions/productsActions";
import { dispatchItemsInBatches } from "helpers/storeHelper";
import ShortcutBtn from "new/components/RedirectMenu/ShortcutBtn/ShortcutBtn";
import { Box } from "@material-ui/core";

class StorePage extends Component {
  constructor(props) {
    super(props);
    this.notificationCentralWarningSeen =
      this.notificationCentralWarningSeen.bind(this);
    this.openNotificationCenterModal =
      this.openNotificationCenterModal.bind(this);
    this.toggleSearchLoading = this.toggleSearchLoading.bind(this);
    this.sideBar = null;
    this.currentMarket = props.markets;
    this.state = {
      notificationModalOpen: false,
      products: [],
      isSearchLoading: false,
      birthdateModal: false,
      user: {},
      showAgeInput: false,
      searchData: [],
      showFilterMedicineKind: false,
      searchPage: 1,
      isFetchingMore: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const marketID = Number(this.props.match.params.market_id);
    const { searchText = "", searchActive } = this.props;
    if (searchActive && nextProps.searchText !== searchText) {
      this.searchProduct(nextProps.searchText, marketID);
    }
  }

  async componentWillMount() {
    window.removeEventListener('scroll', this.handleScroll);
    const marketID = Number(this.props.match.params.market_id);
    const { markets, setMarket, openDeliveryMethod } = this.props;
    openDeliveryMethod(false);
  
    // Check if localStorage.market_id is not set
    if (!localStorage.market_id) {
      localStorage.market_id = marketID;
    }
  
    if (
      (marketID && marketID !== markets.id) ||
      (marketID && !markets && !markets.deliveryMethod)
    ) {
      const response = await getMarketById(Number(marketID));
      setMarket(response);
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const category = this.props.match.params.category;
    const marketID = this.props.match.params.market_id;
    const { searchActive, searchText } = this.props

    if (
      hasAnotherMarketMix(this.props.cart, this.props.match.params.market_id)
    ) {
      this.props.clearCart();
    }
    
    
    if (searchActive) {
      this.searchProduct(searchText, marketID)
    } else {
      this.fetchCategoryProducts(marketID, category);
    }
    this.openNotificationCenterModal();
  }

  async componentDidUpdate(props) {
    const marketID = this.props.match.params.market_id;
    
    const {
      searchActive,
      searchText,
      categories,
      selectedCategory,
      selectedSubcategory,
      selectedFilter,
      categoryProducts,
      ordenation,
      categoryProductsChanged,
      subcategoryProductsChanged,
      filterProductsChanged,
    } = this.props;
    
    if (this.currentMarket?.id !== Number(this.props.match.params.market_id)) {
      if (this.currentMarket.id !== props.markets.id) {
        this.currentMarket = props.markets;
        if (hasAnotherMarketMix(this.props.cart, props.markets.id)) {
          this.props.clearCart();
        }
        window.location.reload();
      }
    } else if (props.markets && props.markets.id !== Number(marketID)) {
      if (props.markets.id) {
        this.props.history.push(`/loja/${props.markets.id}`);
      }
    }
    if (ordenation !== props.ordenation) {
      const mixesOrdered = getMixesOrdered(
        selectedCategory,
        selectedSubcategory,
        selectedFilter,
        categoryProducts,
        categories,
        ordenation
      );

      switch (true) {
        case Boolean(selectedFilter):
          filterProductsChanged(mixesOrdered);
          break;
        case Boolean(selectedSubcategory):
          subcategoryProductsChanged(mixesOrdered);
          break;
        default:
          categoryProductsChanged(mixesOrdered);
          break
      }
    }

    if (selectedCategory?.id !== props.selectedCategory?.id) {
      this.fetchCategoryProducts(marketID, selectedCategory?.id);
    }

    if (searchActive !== props.searchActive) {
      this.searchProduct(searchText, marketID);
    }
  }

  fetchCategoryProducts = async (marketID, section) => {
    this.toggleSearchLoading();
    const { selectedCategory, deliveryMethod, categoryProductsChanged, zone } = this.props;
    const categoryID = section || selectedCategory?.id

    let allMixes;   
    if (section === "clube") {
      allMixes = await this.getClubeOffers(marketID);
    } else {
      allMixes = await fetchCategoryItems(
        marketID, 
        categoryID, 
        deliveryMethod.deliveryMethod?.id, 
        zone?.neighborhood_id);
    }

    this.toggleSearchLoading();
    dispatchItemsInBatches(allMixes, categoryProductsChanged);
    window.scrollTo(0, 0);
  };

  getClubeOffers = async (marketID) => {
    const { changeSelectedCategory } = this.props;
    const userCPF = localStorage.getItem("crmUserCpf");
  
    try {
      if (userCPF) {
        let allMixes = [];
        let page = 1;
        let hasMoreMixes = true;
  
        while (hasMoreMixes) {
          const { deliveryMethod, zone } =  this.props
          const club_offers = await getOffers(
              marketID, 
              page, 
              deliveryMethod.deliveryMethod?.id, 
              zone?.neighborhood_id, 
              );
          if (club_offers.mixes.length === 0) {
            hasMoreMixes = false;
          } else {
            allMixes = [...allMixes, ...applyProductFunctions(extractItemsFromMixes(club_offers))];
            page++;
          }
        }
  
        changeSelectedCategory({ id: "clube" });
        return allMixes;
      }
    } catch (error) {
      notification("Você precisa fazer login no clube de descontos novamente!", "warning");
      changeSelectedCategory({ id: "clube" });
      localStorage.removeItem("crmUserCpf");
    }
  };
  
  searchProduct = debounce(async (searchText, marketID, page = 1) => {
    this.toggleSearchLoading();
    try {
      const results = await search(searchText, marketID, page);
      window.history.pushState({}, "Search", this.props.match.url + "?search=" + searchText);
      
      if (page === 1) {
        this.handleSearchResults(results, searchText);
        window.scrollTo(0, 100); 
      } else {
        this.appendSearchResults(results);
      }
    } catch (error) {
      console.log(error);
      notification("Ocorreram erros, verifique sua conexão com a internet", "error");
    } finally {
      this.toggleSearchLoading();
      this.setState({ isFetchingMore: false });
    }
  }, 500);

  appendSearchResults = (newResults) => {
    const { searchProductsChanged, searchProducts } = this.props;
    const newItems = extractItemsFromMixes(newResults);
    const currentItems = searchProducts || [];
    const allItems = [...currentItems, ...newItems];
    searchProductsChanged(allItems);
  };

  handleSearchResults(searchData, searchText) {
    const { searchProductsChanged } = this.props;
    const searchItems = extractItemsFromMixes(searchData);

    if (searchData.medicine_kinds && searchData.medicine_kinds.length > 0) {
      this.setState({
        searchData: searchData,
        showFilterMedicineKind: true
        })
    }

    if (searchItems?.length > 0) {
      sendSearchEvent(searchText, searchItems);
    } else {
      sendEmptySearchEvent(searchText, searchItems);
    }

    searchProductsChanged(searchItems);
  }

  handleMedicineItems = async (medicineId, medicineLabel) => {
    const { searchProductsChanged } = this.props;
    const searchItems = extractItemsFromMixes(this.state.searchData);
    let filterMedicine; 

    switch(medicineId){
      case "reference":
        filterMedicine = searchItems.filter(medicine => medicine.id === "reference")
        searchProductsChanged(filterMedicine);
        break;
      case "similar":
        filterMedicine = searchItems.filter(medicine => medicine.id === "similar")
        searchProductsChanged(filterMedicine);
        break;
      case "generics":
        filterMedicine = searchItems.filter(medicine => medicine.id === "generics")
        searchProductsChanged(filterMedicine);
        break;
      default:
    }
  }

  toggleSearchLoading() {
    this.setState(
      (state) => ({
        isSearchLoading: !state.isSearchLoading,
      }),
    );
  }

  notificationCentralWarningSeen() {
    return this.props.client_attributes?.brand_customer
      ?.notification_central_warning_seen;
  }

  openNotificationCenterModal() {
    const { client_attributes, userInfoChanged, brand } = this.props;
    if (!!client_attributes.id && !this.notificationCentralWarningSeen()) {
      this.setState({
        notificationModalOpen: true,
      });
      updateBrandCustomer(client_attributes, brand.id);
      userInfoChanged({
        ...client_attributes,
        brand_customer: {
          ...client_attributes.brand_customer,
          notification_central_warning_seen: true,
        },
      });
    }
  }

  handleScroll = () => {
    const marketID = this.props.match.params.market_id;
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 125) {
      const { isFetchingMore, searchPage } = this.state;
      const { searchText, searchActive, isSearchLoading } = this.props;
      if (searchActive && !isSearchLoading && !isFetchingMore) {
        this.setState({ isFetchingMore: true, searchPage: searchPage + 1 }, () => {
          this.searchProduct(searchText, marketID, searchPage);
        });
      }
    }
  };

  render() {
    const { selectedCategory = {}, hasAutoCoupons, searchActive } = this.props;
    const { product_cb, product_slug } = this.props.match.params;
    const userCPF = localStorage.getItem("crmUserCpf");
    const { isSearchLoading, birthdateModal } = this.state;

    return isSearchLoading ? (
      <div className="main-content mobile-home-padding">
         <HomePageSkeleton/>
      </div>
    ) : this.props.selectedCategory?.id === "clube" && !userCPF ? (
      <CRMPage showModal={!userCPF} />
    ) : (
      <section className="section" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div>
          <div
            className={`${
              !selectedCategory.id ? "main-content" : "main-content with-banner"
            }`}
            style={{
              backgroundColor: "#f8f8f8",
              marginTop: hasAutoCoupons ? 0 : -40,
            }}
          >
          {this.state.showFilterMedicineKind && searchActive &&
            <Box className="redirect-menu" style={{ position: "relative", top: "3em" }}>
              {this.state.searchData?.medicine_kinds?.map((item) => (
                <ShortcutBtn
                  key={item.id}
                  label={item.label}
                  onClick={() => this.handleMedicineItems(item?.id, item?.label)}
                />
              ))}
            </Box>}
            {(product_cb || product_slug) && !searchActive ? (
              <ProductView />
            ) : (
              <ProductsView />
            )}
            {birthdateModal &&
              (this.state.user.age === undefined ||
                this.state.user.age < 18) && (
                <BirthdayModal
                  showModal={birthdateModal}
                  showAgeInput={this.state.showAgeInput}
                  user={this.state.user}
                  setState={(user, showAgeInput) =>
                    this.setState({ ...this.state, user, showAgeInput })
                  }
                  toggleSubcategory={this.toggleSubcategory}
                  toggleModal={() =>
                    this.setState({ ...this.state, birthdateModal: false })
                  }
                />
              )}
            <NotificationCenterWarningModal
              isOpen={false}
              toggleModal={() => {
                this.setState({
                  notificationModalOpen: false,
                });
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    zone: state.zone,
    products: state.currentMarket,
    markets: state.markets,
    client_attributes: state.userInfo,
    offers: state.marketStuff.offers,
    categories: state.marketStuff.categories,
    cart: state.cart.cart,
    discount_crm: state.crm.crmData,
    deliveryMethod: state.deliveryMethod,
    selectedCategory: state.categories.selectedCategory,
    subcategories: state.categories.subcategories,
    selectedSubcategory: state.categories.selectedSubcategory,
    selectedFilter: state.categories.selectedFilter,
    hasAutoCoupons: state.settings.hasAutoCoupons,
    searchText: state.ui.searchText,
    searchActive: state.ui.searchActive,
    ordenation: state.ui.ordenation,
    brand: state.brand.brand,
    categoryProducts: state.products.categoryProducts,
    searchProducts: state.products.searchProducts,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userInfoChanged,
      categoryProductsChanged,
      subcategoryProductsChanged,
      filterProductsChanged,
      searchProductsChanged,
      categoriesChanged,
      bannersChanged,
      setNeighborhood,
      clearCart,
      setZoneForRequestedCep,
      setCRM,
      setMarket,
      openDeliveryMethod,
      changeSelectedCategory,
      clearCRM,
    },
    dispatch
  );
}
StorePage = connect(mapStateToProps, mapDispatchToProps)(StorePage);
StorePage = withRouter(StorePage);
export default loadsContent(React.memo(StorePage));
