import axios from "axios";
import config from "config";
import firebase from "firebase/app";
import { oAuthData, root, getBrandID } from "../../config";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";

export async function getAccessToken(cpf) {
  let params = oAuthData

  if (cpf) {
    const firebaseToken = await firebase
      .auth()
      .currentUser.getIdToken(true)

    params = {
      brand_id: getBrandID(),
      cpf: cpf,
      grant_type: "assertion",
      assertion: firebaseToken,
    }
  }

  try {
    const response = await axios.post(`${root()}/oauth/token`, params, {});
    if (response.data.access_token) {
      var encryptedAccessToken = window.aes.encrypt(
        response.data.access_token,
        "mcSiteToken123"
      );
      var encryptedRefreshToken = window.aes.encrypt(
        response.data.refresh_token,
        "mcSiteToken123"
      );
      localStorage.setItem("accessToken", encryptedAccessToken);
      localStorage.setItem("refreshToken", encryptedRefreshToken);
    }
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function refreshToken() {
  const refreshToken = window.aes.decrypt(localStorage.getItem("refreshToken"), "mcSiteToken123").toString(
    window.CryptoJS.enc.Utf8
  )
  let params = {
    ...oAuthData,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  }

  try {
    const response = await axios.post(`${root()}/oauth/token`, params, {});
    if (response.data.access_token) {
      var encryptedAccessToken = window.aes.encrypt(
        response.data.access_token,
        "mcSiteToken123"
      );
      var encryptedRefreshToken = window.aes.encrypt(
        response.data.refresh_token,
        "mcSiteToken123"
      );
      localStorage.setItem("accessToken", encryptedAccessToken);
      localStorage.setItem("refreshToken", encryptedRefreshToken);
    }

    return response.data;
  } catch (error) {


    return error.response;
  }
}
export async function sendDataMail(id) {
  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/customers/${id}/send_data_mail/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAppNotices() {
  const marketID = localStorage.getItem("market_id") ? localStorage.getItem("market_id") : localStorage.getItem("marketIdCurrent")
  const customerID = localStorage.getItem("USER_ID");

  const urlParams = customerID
    ? `market_id=${marketID}&customer_id=${customerID}&app_screen=offers_screen`
    : `market_id=${marketID}&app_screen=offers_screen`;

  try {
    const response = await oAuthRequest.get(
      `${config.mappURLV2}/app_notices/active?${urlParams}`
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export async function getAppNoticesCep() {
  const customerId = localStorage.getItem("USER_ID");
  const brandId = getBrandID();
  const params = {
    brand_id: brandId,
    customer_id: customerId,
    app_screen: 'ceps_screen'
  };

  const url = `${config.mappURLV2}/app_notices/active?`;
  try {
    const response = await oAuthRequest.get(url, {params: params})
    return response.data
  } catch (error) {
    return error.response
  }
}