import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/app";
import { StyledFirebaseAuth } from "react-firebaseui";
import Messages from "../Messages/Messages";

import { domainColorStyle } from "config";
import { notification } from "helpers/util";
import "../../assets/css/react-confirm-alert.css";
import "./Chat.scss";

import ForumRoundedIcon from "@material-ui/icons/ForumRounded";

import { updateStyled } from "helpers/util";
import getOneSignalPlayerId from "helpers/oneSignalHelper";

import { UserModal } from "new/components/UserPage/UserModal/UserModal";
import { useSelector } from "react-redux";

import ButtonBackTop from "new/components/Button/ButtonBackTop/ButtonBackTop";
import { getUiConfig } from "enums/uiConfig";
import { getCustomerByCpf } from "helpers/apiHelper";

function ChatFirebase({ marketId }) {
  const [showToggle, setShowToggle] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [has_general_chat, setHasChat] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const userInfo = useSelector(({ userInfo }) => userInfo);
  const [uiConfig, setUiConfig] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState({})
  const brand = useSelector(({ brand }) => brand.brand)

  function getIdToken() {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        localStorage.setItem(
          "idToken",
          window.aes.encrypt(idToken, "mcSiteToken123")
        );

      })
      .catch((error) => console.log(error));
  }


  const authObserver = useCallback(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !customer) {
        getIdToken()
        setFirebaseRecoveredInfo({
          name: user?.displayName,
          phone_number: user?.phoneNumber?.split("+55").join(""),
          email: user?.email,
        })
        setSignedIn(!!user);
      }
    });
  }, [customer]);

  const getChatGeral = useCallback(async () => {
    if (customer) {
      var messagensRef = firebase
        .database()
        .ref(`${marketId}/customer_${customer.id}/messages`);
      messagensRef.on("value", (snapshot) => {
        const response = snapshot.val();

        if (response) {
          setChatMessages(response);
        } else {
          setChatMessages([]);
        }
      });
    }
  }, [customer, marketId]);

  async function getAuthConfig(brand) {
    const response = await getUiConfig(brand);
    setUiConfig(response);
  }

  useEffect(() => {
    getAuthConfig(brand);
  }, []);

  useEffect(() => {
    if (userInfo?.id !== (undefined || null)) {
      findCustomerById();
    } else {
      firebase.auth().signOut();
    }
  }, [userInfo]);

  useEffect(() => {
    if (customer) {
      getChatGeral();
    } else {
      getBrand();
      authObserver();
    }
  }, [customer]);

  async function getBrand() {
    if (brand.has_general_chat) {
      setHasChat(true);
    }
  }

  const findCustomerById = () => {
    const id = localStorage.getItem("USER_ID");
    if (id !== null) {
      getCustomerByCpf((error, data) => {
        if (error) {
          console.log(error);
        } else {
          setCustomer(data.customer);
        }
      });
    } else {
      return;
    }
  };

  const sendMessage = async () => {
    try {
      if (newMessage === "") return;
      const playerId = await getOneSignalPlayerId();
      firebase
        .database()
        .ref(`${marketId}/customer_${customer.id}`)
        .update({
          name: localStorage.getItem("USERNAME"),
          no_customer_message: false,
          no_market_message: true,
          updated_at: Date.now(),
          player_id: playerId,
        });

      firebase
        .database()
        .ref(`${marketId}/customer_${customer.id}/messages`)
        .push({
          content: newMessage,
          created_at: Date.now(),
          is_from_market: false,
        });
      setNewMessage("");
    } catch {
      notification("Ocorreu um erro ao enviar sua mensagem.", "danger");
    }
  };

  return has_general_chat ? (
    <>
      {showToggle ? (
        <section
          className="floating-button-right chat-floating-button-right"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            color="secondary"
            style={{
              ...domainColorStyle("background"),
              borderRadius: "10pc",
              height: 45,
              width: 45,
              marginRight: 10,
              zIndex: 1000,
              color: "white",
            }}
            className="button"
            onClick={() => setShowToggle(false)}
          >
            <div>
              <ForumRoundedIcon color="inherit" />
            </div>
          </div>
          <ButtonBackTop />
        </section>
      ) : (
        <div className="floating-button-right chat-floating-button-right chat-menu shadow">
          {!signedIn && uiConfig?.signInOptions.length ? (
            <section className="section">
              <div
                className="header-chat"
                style={domainColorStyle("background")}
              >
                <p>Fale conosco!</p>
                <button onClick={() => setShowToggle(true)}>x</button>
              </div>
              <StyledFirebaseAuth
                className="section t-5 shadow-none"
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
              {updateStyled()}
            </section>
          ) : (
            <div className="text-center h-100 w-100">
              <UserModal
                isOpen={signedIn || (!uiConfig?.signInOptions.length)}
                firebaseRecoveredInfo={firebaseRecoveredInfo}
              />

              <div
                className="header-chat"
                style={{
                  ...domainColorStyle("background"),
                  justifyContent: "space-between",
                  color: "#fff",
                }}
              >
                <strong>Fale conosco!</strong>
                <button onClick={() => setShowToggle(true)}>
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div
                style={{
                  marginTop: "3.1rem",
                  overflow: "auto",
                  height: "calc(100% - 3.1rem)",
                }}
              >
                <Messages chatMessages={chatMessages} />
                <div className="input-chat">
                  <input
                    onKeyDown={({ key }) =>
                      key === "Enter" ? sendMessage() : null
                    }
                    value={newMessage}
                    onChange={({ target }) => setNewMessage(target.value)}
                  />
                  <button
                    style={{
                      ...domainColorStyle("backgroundColor"),
                      padding: 5,
                      borderRadius: 100,
                      marginLeft: 10,
                      maxWidth: 35,
                    }}
                    onClick={() => sendMessage()}
                  >
                    <i
                      className=" far text-exclamation fa-paper-plane mr-1"
                      style={{ color: "#fff", fontSize: 18 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <></>
  );
}

export default React.memo(ChatFirebase);
