import React, { useState } from "react";
import Modal from "new/components/Modal/Modal";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";

import "./PhoneAuthenticationLogin.scss"
import InputMask from "components/InputMaskCustom";
import { getPhoneAuthenticationMethod, getPhoneAuthenticationCredential} from "helpers/activeAuthMethods";
import firebase from "firebase/app";
import { phoneNumberTest } from "helpers/phoneNumberTest";
import { showNotification } from "helpers/util";
import { unmask } from "helpers/util";


export default function PhoneAuthenticationLogin({ showModal, toggleModal }) {
  const [phoneData, setPhoneData] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [confirmationResult, setConfirmationResult] = useState({})
  const [showCode, setShowCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  
  const handleChangePhoneNumber = (e) => {
    const phone = unmask(e.target.value)
    setIsDisabled(phone?.length === 0)
    const { name, value } = e.target
    setPhoneData({ ...phoneData, [name]: value })
  }

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: function (response) {
          console.log("recature resolved")
          onSignup();
        }
      });
    }
  }

  const onSignup = async () => {
    const isPhoneValid = phoneNumberTest(phoneData.phone_number)
    if (!isPhoneValid) {
      showNotification("top", "center", "O número de telefone deve ser válido", "warning")
      setIsLoading(false)
      return
    }
    setIsDisabled(true)
    setIsLoading(true)
    onCaptchVerify()
    const appVerifier = window.recaptchaVerifier
    const formatPhone = "+55" + phoneData.phone_number

    const result = await getPhoneAuthenticationMethod(formatPhone, appVerifier)
    if (!result.message) {
      setConfirmationResult(result)
      setIsLoading(false)
      setShowCode(true)
    } else {
      setIsLoading(false)
      if (result.code === "auth/too-many-requests") {
        setErrorMessage("O número de tentativas de login por este método foi excedido. Por favor, tente novamente mais tarde ou utilize um método alternativo.")
      }
    }
  }

  const onOTPVerify = async () => {
    const verificationId = confirmationResult.verificationId
    const formatPhoneCode = phoneData.phone_verification_code.replace(/-/g, "")

    const credential = await getPhoneAuthenticationCredential(verificationId, formatPhoneCode)
    if(!credential.message){
      setShowCode(false)
      toggleModal(false)
    }else{
      if(credential.code === "auth/invalid-verification-code"){
        setErrorMessage("O código de verificação SMS usado para criar a credencial de autenticação do telefone é inválido.")
      }
    }
  }

  const logout = () => {
    setPhoneData("")
    setErrorMessage("")
    setShowCode(false)
    setIsLoading(false)
    toggleModal(false)
  }

  return (
    <Modal
      isOpen={showModal}
      toggleModal={() => toggleModal(false)}
      className="modal-user-login">
      <Box style={{ padding: "2rem" }}>
        <Button
          color="primary"
          onClick={() => logout()}
          className="button-logout-modal-user"
        >
          Cancelar
        </Button>
        <Box className="box-phone-login-title">
          <p>Acessar</p>
          {!showCode ?
            (<span>Insira o número do seu telefone</span>)
            : (<span>Confirme seu número de telefone</span>)}
        </Box>
        {isLoading ? (
          <Box style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            {errorMessage ? (
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                  color: "red",
                  fontSize: 18
                }}>{errorMessage}</Typography>
            ) : (
              <Grid container spacing={3} style={{ marginTop: "2em" }}>
                {!showCode ? (
                  <>
                    <Grid item xs={12}>
                      <span>Número de telefone</span>
                      <InputMask
                        required
                        type="tel"
                        mask="(99)99999-9999"
                        name="phone_number"
                        value={phoneData.phone_number}
                        onChange={handleChangePhoneNumber}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-next-email-login"
                      onClick={() => onSignup()}
                      disabled={isDisabled}>
                      Confimar número de telefone
                    </Button>
                    <span className="title-alert-auth-phone">
                      Se você tocar em “Confirmar número de telefone”, um SMS poderá ser enviado e tarifas de mensagem e de dados serão cobradas.
                    </span>
                  </>
                ) : (
                  <>
                    <div>
                      <p style={{ lineHeight: 0 }}>Insira o código de 6 dígitos enviado para</p>
                      <span>{phoneData?.phone_number}</span>
                    </div>
                    <p></p>
                    <Grid item xs={12}>
                      <InputMask
                        required
                        type="tel"
                        mask="999-999"
                        name="phone_verification_code"
                        value={phoneData?.phone_verification_code}
                        onChange={handleChangePhoneNumber}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-next-email-login"
                      onClick={() => onOTPVerify()}
                      disabled={isDisabled}
                    >
                      Continuar
                    </Button>
                  </>
                )}
              </Grid>
            )}
          </>
        )
        }
      </Box >
      <div id="recaptcha-container"></div>
    </Modal >
  )
}