import {
  FORYOU_PRODUCTS_CHANGED,
  HIGHLIGHTED_PRODUCTS_CHANGED,
  CATEGORY_PRODUCTS_CHANGED,
  SUBCATEGORY_PRODUCTS_CHANGED,
  FILTER_PRODUCTS_CHANGED,
  SEARCH_PRODUCTS_CHANGED,
  CLUB_OFFERS_CHANGED,
  PUBLICITY_PRODUCTS_CHANGED,
  CURRENT_OFFERS_CHANGED,
  CURRENT_MENU_PRODUCTS_CHANGED
} from "ActionTypes";

export function currentMenuProductsChanged(allProductsMenu) {
  return {
    type: CURRENT_MENU_PRODUCTS_CHANGED,
    payload: allProductsMenu
  }
}

export function currentOffersChanged(currentOffers) {
  return {
    type: CURRENT_OFFERS_CHANGED,
    payload: currentOffers,
  };
}

export function publicityProductsChanged(publicityProducts) {
  return {
    type: PUBLICITY_PRODUCTS_CHANGED,
    payload: publicityProducts,
  };
}

export function highlightedProductsChanged(highlightedProducts) {
  return {
    type: HIGHLIGHTED_PRODUCTS_CHANGED,
    payload: highlightedProducts,
  };
}

export function clubOffersChanged(clubOffers) {
  return {
    type: CLUB_OFFERS_CHANGED,
    payload: clubOffers,
  };
}

export function forYouProductsChanged(forYouProducts) {
  return {
    type: FORYOU_PRODUCTS_CHANGED,
    payload: forYouProducts,
  };
}

export const categoryProductsChanged = (categoryProducts) => {
  return {
    type: CATEGORY_PRODUCTS_CHANGED,
    payload: categoryProducts,
  };
}

export const subcategoryProductsChanged = (subcategoryProducts) => {
  return {
    type: SUBCATEGORY_PRODUCTS_CHANGED,
    payload: subcategoryProducts,
  };
}

export const filterProductsChanged = (filterProducts) => {
  return {
    type: FILTER_PRODUCTS_CHANGED,
    payload: filterProducts,
  };
}

export const searchProductsChanged = (searchProducts) => {
  return {
    type: SEARCH_PRODUCTS_CHANGED,
    payload: searchProducts,
  };
}
