import React, { useState } from "react";
import Modal from "new/components/Modal/Modal";
import { Box, Button, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import firebase from "firebase/app";

import "./EmailAuthenticationLogin.scss";
import Input from "components/InputCustom";
import { getEmailAuthenticationMethod, createAccountWithEmailAndPassword } from "helpers/activeAuthMethods";
import { showNotification } from "helpers/util";
import { getGoogleAuthenticationMethod } from "helpers/activeAuthMethods";

export default function EmailAuthenticationLogin({ showModal, toggleModal }) {
  const [emailData, setEmailData] = useState({})
  const [emailExists, setEmailExists] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [problemsLoggin, setProblemsLoggin] = useState(false)
  const [createAccountEmail, setCreateAccountEmail] = useState(false)
  const [ emailAuthentication, setEmailAuthentication] = useState(false)
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailData.email)
  const disableLoginPasswordButton = !emailData.password || emailData.password?.length < 4
  const isEmailDataValid = emailData?.name?.length > 2 && emailData?.password?.length > 3


  const handleChangeEmail = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setEmailData({ ...emailData, [name]: value })
  }
  
  const submitEmail = async (e) => {
    e.preventDefault()
    await checkEmailExists()
  }

  const submitPassword = async (e) => {
    e.preventDefault()
    const response = await getEmailAuthenticationMethod(emailData.email, emailData.password)
    if (response.message) {
      showNotification(
        "top",
        "center",
        "Senha incorreta",
        "warning")
      return
    } else {
      toggleModal(false)
    }
  }

  const checkEmailExists = async () => {
    if (isEmailValid) {
      try {
        const methods = await firebase.auth().fetchSignInMethodsForEmail(emailData.email);
        if(methods[0] === "google.com"){
          setEmailAuthentication(true)
          return
        }
        methods[0] === "password"
          ? setEmailExists(true)
          : setCreateAccountEmail(true)
      } catch (error) {
        console.error("Erro ao verificar e-mail:", error)
      }
    }
  }

  const createAccount = async () => {
    try {
      const response = await createAccountWithEmailAndPassword(emailData.email, emailData.password, emailData.name)
      if (response.code === "auth/weak-password" && response.message) {
        showNotification(
          "top",
          "center",
          "A senha deve ter pelo menos 6 caracteres",
          "warning")
        return
      } else {
        finalizeAuthentication()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendRecoveryInstructions = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(emailData.email)
      showNotification(
        "top",
        "center",
        `Siga as instruções enviadas para ${emailData.email}`,
        "success")
      finalizeAuthentication()
    } catch (error) {
      showNotification(
        "top",
        "center",
        `Tivemos um problema ao tentar enviar as instruções para ${emailData.email}. Tente novamente ou entre em contato com o suporte.`,
        "warning")
    }
  }

  const finalizeAuthentication = () => {
    setEmailExists(false)
    setProblemsLoggin(false)
    setCreateAccountEmail(false)
    setEmailData("")
    toggleModal(false)
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        toggleModal={() => finalizeAuthentication()}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => finalizeAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Acessar</p>
            {!emailExists ?
              (<span>Insira seu email</span>)
              : (<span>Você já usou o e-mail <b>{emailData.email}</b> para fazer login. Insira a senha dessa conta. </span>)}
          </Box>
          {!emailExists ? (
            <form onSubmit={submitEmail}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span>Email</span>
                  <Input
                    required
                    type="email"
                    placeholder="example123@gmail.com"
                    name="email"
                    showLabel={false}
                    onChange={handleChangeEmail}
                  />
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  className="button-next-email-login"
                  type="submit"
                  disabled={!isEmailValid}>
                  Próximo
                </Button>
              </Grid>
            </form>) : (
            <form onSubmit={submitPassword}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span>Senha</span>
                  <div className="email-authe-password">
                    <Input
                      required
                      type={showPassword ? "text" : "password"}
                      name="password"
                      showLabel={false}
                      defaultValue={emailData.password}
                      onChange={handleChangeEmail}
                    />
                    <InputAdornment position="end" className="icon-visibility-password">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility /> }
                      </IconButton>
                    </InputAdornment>
                    <Button
                      onClick={() => (
                        setProblemsLoggin(true),
                        toggleModal(false))}
                      className="button-problems-email-login">
                      Problemas para fazer login?
                    </Button>
                  </div>
                </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    className="button-next-email-login"
                    type="submit"
                    disabled={disableLoginPasswordButton}>
                    Próximo
                  </Button>
              </Grid>
            </form>
          )}
        </Box>
      </Modal>

      <Modal
        isOpen={problemsLoggin}
        toggleModal={() => (setProblemsLoggin(false), toggleModal(true))}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => finalizeAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Recuperar senha</p>
            <span>Receba instruções enviadas para este e-mail que explicam como redefinir sua senha</span>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                required
                type="email"
                name="email"
                showLabel={false}
                defaultValue={emailData.email}
                onChange={handleChangeEmail}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className="button-next-email-login"
              onClick={() => sendRecoveryInstructions()}>Enviar
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal
        isOpen={createAccountEmail}
        toggleModal={() => (setCreateAccountEmail(false), toggleModal(true))}
        className="modal-user-login">
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => finalizeAuthentication()}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Criar conta</p>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} className="email-authe-password">
              <span>Email*</span>
              <Input
                disabled
                required
                type="email"
                name="email"
                showLabel={false}
                defaultValue={emailData.email}
                onChange={handleChangeEmail}
              />
              <span>Nome*</span>
              <Input
                required
                type="name"
                name="name"
                showLabel={false}
                placeholder="Nome"
                defaultValue={""}
                onChange={handleChangeEmail}
              />
              <span>Senha*</span>
              <Input
                required
                type={showPassword ? "text" : "password"}
                name="password"
                showLabel={false}
                placeholder="senha"
                defaultValue={""}
                onChange={handleChangeEmail}
              />
              <InputAdornment position="end" className="icon-visibility-password">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className="button-next-email-login"
              onClick={() => createAccount()}
              disabled={!isEmailDataValid}
            >
              Salvar
            </Button>
          </Grid>
        </Box>
      </Modal>

      <Modal
        isOpen={emailAuthentication}
        toggleModal={() => setEmailAuthentication(false)}
        className="modal-user-login"
      >
        <Box style={{ padding: "2rem" }}>
          <Button
            color="primary"
            onClick={() => setEmailAuthentication(false)}
            className="button-logout-modal-user"
          >
            Cancelar
          </Button>
          <Box className="box-email-login-title">
            <p>Você já tem uma conta</p>
            <span style={{marginTop: "5%"}}>
              Você já usou <b>{emailData.email}</b><br/>
              Faça login com o Google para continuar.
            </span>
          </Box>
          <div className="auth-email-google-button">
            <Button
              variant="contained"
              color="primary" onClick={getGoogleAuthenticationMethod}>
              Faça login no Google
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
