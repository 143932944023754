import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router";
import { extractItemsFromMixes } from "helpers/storeHelper";
import { filterItemsByCategory } from "helpers/categoriesHelper";
import { currentMenuProductsChanged } from "actions/productsActions";
import { getHighlightedProducts } from "helpers/apiHelper";
import { getMarketOffers } from "helpers/apis/marketInfo";
import { applyProductFunctions } from "helpers/models/product";
import { fetchCategoryItems } from "helpers/storeHelper";
import StorePage from "containers/StorePage";
import ProductsSlider from "new/components/ProductsSlider/ProductsSlider";
import SeeMoreButton from "../Offers/components/SeeMoreButton/SeeMoreButton"
import OffersPageSkeleton from "../Offers/OffersPageSkeleton"
import SectionsMenu from "../../components/SectionsMenu/SectionsMenu"
import { useMediaQuery } from "@material-ui/core";

function HomeMenu() {
  const { market_id } = useParams();
  const dispatch = useDispatch();
  const [numDisplayedCategories, setNumDisplayedCategories] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsOffersHighlights, setItemsOffersHighlights] = useState([])
  const categories = useSelector(({ categories }) => categories.categories);
  const currentProductsMenu = useSelector(({ products }) => products.allProductsMenu)
  const searchActive = useSelector(({ ui }) => ui.searchActive)
  const deliveryMethodId = useSelector(({ deliveryMethod }) => deliveryMethod.deliveryMethod?.id)
  const allCategories = categories.filter(category => category.id !== 'paravoce')
  const limit = 20
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const hasMoreCategories =
    // verifica se o número de categorias mostradas é menor do que o número de categorias no array
    numDisplayedCategories < allCategories.length &&
    // verifica se o index da última categoria mostrada é menor que o index da última categoria do array
    numDisplayedCategories - 1 < allCategories.length - 1;

  async function getOffersAndHighlighted() {
    setIsLoading(true);
    try {
      const [offers, highlights] = await Promise.all([
        getMarketOffers(market_id, deliveryMethodId, true),
        getHighlightedProducts(market_id, deliveryMethodId),
      ])
      let combinedItems = [];
      combinedItems = combinedItems.concat(extractItemsFromMixes(highlights));
      combinedItems = combinedItems.concat(extractItemsFromMixes(offers));
      combinedItems = combinedItems.slice(0, 20);
      setItemsOffersHighlights(combinedItems)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function getAllCategoriesProducts() {
    setIsLoading(true);
    try {
      const promises = allCategories.map(async (category) => {
        const categoryItems = await fetchCategoryItems(market_id, category.id, deliveryMethodId, limit)
        return categoryItems
      })
      const results = await Promise.all(promises)
      const items = results.flat()
      dispatch(currentMenuProductsChanged(items))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOffersAndHighlighted()
    getAllCategoriesProducts()
  }, [categories, market_id]);

  function showMoreCategories() {
    setNumDisplayedCategories(numDisplayedCategories + 5);
  }


  return (
    <>
      {searchActive ? (
        <StorePage />
      ) : (
        <div className="main-content mobile-home-padding">
          {isLoading ? (
            <OffersPageSkeleton />
          ) : (
            <>
              {itemsOffersHighlights.length > 0 ? (
                <ProductsSlider
                  section={{
                    display_name: "Destaques",
                    items: applyProductFunctions(itemsOffersHighlights),
                    id: "destaques"
                  }}
                  items={applyProductFunctions(itemsOffersHighlights)}
                />
              ) : null}

                {isSmallScreen ? <SectionsMenu sections={allCategories} /> : null}
                <>
                  {allCategories
                    .slice(0, numDisplayedCategories)
                    .map((category) => (
                      <ProductsSlider
                        key={category.id}
                        section={category}
                        items={filterItemsByCategory(currentProductsMenu, category)}
                      />
                    ))}
                </>

              <SeeMoreButton
                hasMoreCategories={hasMoreCategories}
                showMoreCategories={showMoreCategories}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default withRouter(HomeMenu);
